import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ToastService } from "@msi/cobalt";
import { EnvService } from "./env.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root',
})
export abstract class PremiseHazardRestBase implements OnDestroy {
  constructor(
    protected httpClient: HttpClient,
    protected envService: EnvService,
    protected toastService: ToastService,
    protected userService: UserService,
  ) {
  }

  protected CcAdminVersion = this.envService.admin;
  protected MsiProduct = 'PHZ';
  protected environment = this.envService.env1;
  protected domainExtension = this.envService.domainExtension;
  private userInfoSubscription = this.userService.UserInformation.subscribe(userInfo => {
    if (!userInfo || !userInfo.isSuperAdmin) {
      this.customerRoute = '';
    }
    else if (userInfo.isSuperAdmin && userInfo.selectedCustomerId) {
      this.customerRoute = `/${userInfo.selectedCustomerId}/${userInfo.selectedCustomerId}`;
    }
  });    

  protected phzBaseUrl = `https://coregis${this.environment}commandcentral.${this.domainExtension}`;
  //protected phzBaseUrl = `https://localhost:5000`;

  protected queryUrl = "/premisehazards/v2";
  protected adminUrl = "/admin/premisehazards/v2";
  protected commandUrl = "/command/premisehazards/v2";
  protected customerRoute: string;

  ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
  }

  protected addCustomerRoute(): string {
    return this.customerRoute;
  }

  protected createHttpParams(requestOptions: any): URLSearchParams {
    let params = new URLSearchParams();

    for (let key in requestOptions) {
      if (requestOptions[key] !== undefined && requestOptions[key] !== null) {
        params.append(key, requestOptions[key]);
      }
    }
    return params;
  }

  protected showRestError(message: string): void {
    this.toastService.error(message, null, {
      autoDismiss: 10000,
      closeButton: true,
    });
  }

  protected handleHttpError(err: HttpErrorResponse) {
    if (err.status === 0) {
      this.showRestError("The service is not available. Please try again later.");
    }
    else if (err.status === 400) { // Bad Request
      this.showRestError("There was an error with your request: " + err.error.Message);
    }
    else if (err.status === 401) { // Unauthorized
      this.showRestError("You are not authorized to perform this action. You may need to log in again.");
    }
    else if (err.status === 403) { // Forbidden
      this.showRestError("You are not authorized to perform this action.");
    }
    else if (err.status === 404) {
      this.showRestError("The item you are trying to access does not exist, it may have been deleted.");
    }
    else if (err.status === 409) { // Conflict
      this.showRestError("");
    }
    else if (err.status === 412) { // Precondition Failed
      this.showRestError("The item being updated has been modified since you retrieved it. Please refresh the page and try again.");
    }
    else if (err.status === 429 || err.status === 449) { // Too Many Requests
      this.showRestError("The service is busy, please wait a moment and try again.");
    }
    else if (err.status === 500 ||
      err.status === 502 ||
      err.status === 503) { // Internal Server Error
      this.showRestError("An error occurred while processing your request. Please try again later.");
    }
    else {
      this.showRestError(`An error ocurred processing your request: ${err.status} ${err.statusText}`);
    }
  }
}

export interface SuperAdminInfo {
  customerId: string;
  agencyId: string;
}
