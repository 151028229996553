import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { PremiseHazardRestBase } from "./premise-hazard-rest-base";
import { ResponseItem } from "./response-item.model";

@Injectable({
  providedIn: 'root',
})

export class AdminRestService extends PremiseHazardRestBase {
  public initializeCustomer(): Observable<void> {
    const opts = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
    };

    const url = `${this.phzBaseUrl}${this.adminUrl}/init${this.addCustomerRoute()}`;

    return this.httpClient.post<void>(url, null, opts)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const httpError = err as HttpErrorResponse;
          this.handleHttpError(httpError);
        }
        return throwError(() => err);
      }));
  }

  public getSharingCustomers(): Observable<ResponseItem<Array<string>>> {
    const opts = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    const url = `${this.phzBaseUrl}${this.adminUrl}${this.addCustomerRoute()}/sharing`;

    return this.httpClient.get<Array<string>>(url, opts)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const httpError = err as HttpErrorResponse;
          this.handleHttpError(httpError);
        }
        return throwError(() => err);
      }),
        map(response => {
          const result: ResponseItem<Array<string>> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          };
          return result;
        }));
  }

  public updateSharingCustomers(customers: Array<string>, etag: string): Observable<ResponseItem<void>> {
    const opts = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'If-Match': etag,
      }),
      observe: 'response' as 'response',
    };
    const url = `${this.phzBaseUrl}${this.adminUrl}${this.addCustomerRoute()}/sharing`;
    return this.httpClient.put<void>(url, customers, opts)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const httpError = err as HttpErrorResponse;
          this.handleHttpError(httpError);
        }
        return throwError(() => err);
      }),
        map(response => {
          const result: ResponseItem<void> = {
            Item: null,
            ETag: response.headers.get('ETag'),
          };
          return result;
        }));
  }

  public getCustomersConfiguration(): Observable<ResponseItem<boolean>> {
    const opts = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    const url = `${this.phzBaseUrl}${this.adminUrl}${this.addCustomerRoute()}/configuration`;

    return this.httpClient.get<boolean>(url, opts)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const httpError = err as HttpErrorResponse;
          this.handleHttpError(httpError);
        }
        return throwError(() => err);
      }),
        map(response => {
          const result: ResponseItem<boolean> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          };
          return result;
        }));
  }
}
