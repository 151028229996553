import { Injectable } from "@angular/core";
import { Units } from "./premise-hazards/units.model";

@Injectable({
  providedIn: 'root',
})
export class UnitConverterService {
  fromMeters(meters: number, units: Units): number {
    switch (units) {
      case Units.Meter:
        return meters;
      case Units.Foot:
        return meters * 3.28084;
      case Units.Kilometer:
        return meters / 1000;
      case Units.Mile:
        return meters / 1609.34;
    }
  }

  toMeters(distance: number, units: Units): number {
    switch (units) {
      case Units.Meter:
        return distance;
      case Units.Foot:
        return distance / 3.28084;
      case Units.Kilometer:
        return distance * 1000;
      case Units.Mile:
        return distance * 1609.34;
    }
  }
}
