import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  UserInformation: Observable<UserInformation>;

  private userInformation: BehaviorSubject<UserInformation> = new BehaviorSubject<UserInformation>(null);

  constructor() {
    this.UserInformation = this.userInformation.asObservable();
  }

  public setUserInformation(userInformation: UserInformation): void {
    this.userInformation.next(userInformation);
  }
}

export interface UserInformation {
  customerId: string;
  agencyId: string;
  isSuperAdmin: boolean;
  token: string;
  selectedCustomerId: string;
  user: string;
}
