import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, map, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { GetAddressesRequestModel } from "../premise-hazards/get-addresses-request.model";
import { GetPremiseHazardRequestModel } from "../premise-hazards/get-premise-hazard-request.model";
import { GetPremiseHazardTypesRequestModel } from "../premise-hazards/get-premise-hazard-type-request.model";
import { GetPremiseHazardsRequestModel } from "../premise-hazards/get-premisehazards-request.model";
import { PremiseHazardType } from "../premise-hazards/premise-hazard-type.model";
import { PremiseHazard } from "../premise-hazards/premise-hazard.model";
import { GetAddressesResponse } from "./get-address-response.model";
import { GetPremiseHazardTypesResponse } from "./get-premise-hazard-types-response.model";
import { GetPremiseHazardsResponse } from "./get-premise-hazards-response.model";
import { PremiseHazardRestBase } from "./premise-hazard-rest-base";
import { ResponseItem } from "./response-item.model";
import { CcDriveSearchByPathResponse } from "../premise-hazards/cc-drive-search-by-path-response.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})

export class PremiseHazardQueryRestService extends PremiseHazardRestBase {
  public getPremiseHazards(requestOptions: GetPremiseHazardsRequestModel): Observable<GetPremiseHazardsResponse> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
    };

    let params = this.createHttpParams(requestOptions);
    let url = `${this.phzBaseUrl}${this.queryUrl}${this.addCustomerRoute()}/?${params.toString()}`;

    return this.httpClient.get<GetPremiseHazardsResponse>(url, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        })
      )
  }

  public getAddresses(requestOptions: GetAddressesRequestModel): Observable<GetAddressesResponse> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      })
    };

    let params = this.createHttpParams(requestOptions);
    let url = `${this.phzBaseUrl}${this.queryUrl}${this.addCustomerRoute()}/addresses?${params.toString()}`;

    return this.httpClient.get<GetAddressesResponse>(url, opt)
      .pipe(
        catchError((err => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        })));
  }

  public getPremiseHazardType(id: string): Observable<ResponseItem<PremiseHazardType>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response',
    }

    let url = `${this.phzBaseUrl}${this.queryUrl}${this.addCustomerRoute()}/types/${id}`;

    return this.httpClient.get<PremiseHazardType>(url, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map((response) => {
          const result: ResponseItem<PremiseHazardType> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          }
          return result;
        })
      );
  }

  public getPremiseHazard(id: string, requestOptions: GetPremiseHazardRequestModel): Observable<ResponseItem<PremiseHazard>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response',
    };

    let url = `${this.phzBaseUrl}${this.queryUrl}${this.addCustomerRoute()}/${id}`;
    let params = this.createHttpParams(requestOptions);

    if (params.entries.length != 0) {
      url += `?${params.toString()}`;
    }

    return this.httpClient.get<PremiseHazard>(url, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map((response) => {
          const result: ResponseItem<PremiseHazard> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          }
          return result;
        })
      );
  }

  public getPremiseHazardTypes(requestOptions: GetPremiseHazardTypesRequestModel): Observable<GetPremiseHazardTypesResponse> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
    };

    let params = this.createHttpParams(requestOptions);
    let url = `${this.phzBaseUrl}${this.queryUrl}${this.addCustomerRoute()}/types?${params.toString()}`;

    return this.httpClient.get<GetPremiseHazardTypesResponse>(url, opt)
      .pipe(
        catchError(((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        })));
  }

  public getAttachment(premiseHazardId: string, attachmentId: string): Observable<CcDriveSearchByPathResponse> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
      }),
    };

    let url = `${this.phzBaseUrl}${this.queryUrl}/${premiseHazardId}/attachments/${attachmentId}`;

    return this.httpClient.get<CcDriveSearchByPathResponse>(url, opt)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }

          return throwError(() => err);
        })
    )
  }
}
