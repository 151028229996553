import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PremiseHazardType } from '../../premise-hazards/premise-hazard-type.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Units } from '../../premise-hazards/units.model';
import { UnitConverterService } from '../../unit-converter.service';
import { PremiseHazardInputModel } from '../../premise-hazards/premise-hazard-input.model';
import { PremiseHazardTypeInputModel } from '../../premise-hazards/premise-hazard-type-input.model';
import { take } from 'rxjs';
import { ToastService } from '@msi/cobalt';
import { PremiseHazardCommandsRestService } from '../../services/premise-hazard-command-rest.service';
import { ResponseItem } from '../../services/response-item.model';

@Component({
  selector: 'app-premise-hazard-type-item',
  templateUrl: './premise-hazard-type-item.component.html',
  styleUrls: ['./premise-hazard-type-item.component.scss']
})
export class PremiseHazardTypeItemComponent implements OnInit, OnDestroy, OnChanges {
  PremiseHazardTypeForm: FormGroup;

  @Input() SelectedPremiseHazardType: PremiseHazardType;
  @Input() ETag: string;
  @Input() Units: Units;

  @Output() submittedEvent = new EventEmitter<ResponseItem<PremiseHazardType>>();
  @Output() cancelEvent = new EventEmitter<void>();
  @Output() conflictErrorEvent = new EventEmitter<void>();

  IsUpdating: boolean = false;
  IsCreating: boolean = false;
  minDate: NgbDateStruct;
  IsSubmitting: boolean = false;

  DirectHitDistanceErrorText: string = null;

  UNITS_TEXT = ['feet', 'meters', 'kilometers', 'miles'];
  DECIMAL_PLACES = [3, 2, 5, 6];

  constructor(private unitsConverterService: UnitConverterService,
    private restService: PremiseHazardCommandsRestService,
    private toastService: ToastService
  ) { }

  ngOnDestroy(): void { }
  ngOnInit(): void {
    this.IsCreating = !(this.SelectedPremiseHazardType);
    this.IsUpdating = !this.IsCreating;
    this.createPremiseHazardTypeForm();
    const minimumDistance: number = this.roundToDecimalPlaces(this.unitsConverterService.fromMeters(5, this.Units), this.DECIMAL_PLACES[this.Units]);
    switch (this.Units) {
      case Units.Foot:
      case Units.Kilometer:
      case Units.Mile:
        this.DirectHitDistanceErrorText = `Direct Hit Distance must be greater than ${minimumDistance} ${this.UNITS_TEXT[this.Units]} (5 meters)`;
        break;
      case Units.Meter:
        this.DirectHitDistanceErrorText = "Direct Hit Distance must be greater than 5 meters";
        break;
    }
  }
  ngOnChanges(): void { }

  private createPremiseHazardTypeForm(): void {
    let initialDirectHitDistance: number = this.roundToDecimalPlaces(this.unitsConverterService.fromMeters(5, this.Units), this.DECIMAL_PLACES[this.Units]);
    let initialInnerProximityDistance: number = null;
    let initialOuterProximityDistance: number = null;

    if (this.SelectedPremiseHazardType) {
      let maximumDecimalPlaces: number = this.DECIMAL_PLACES[this.Units];
      initialDirectHitDistance = this.roundToDecimalPlaces(this.unitsConverterService.fromMeters(this.SelectedPremiseHazardType.directHitDistance, this.Units), maximumDecimalPlaces);
      initialInnerProximityDistance = this.roundToDecimalPlaces(this.unitsConverterService.fromMeters(this.SelectedPremiseHazardType.innerProximityDistance, this.Units), maximumDecimalPlaces);
      initialOuterProximityDistance = this.roundToDecimalPlaces(this.unitsConverterService.fromMeters(this.SelectedPremiseHazardType.outerProximityDistance, this.Units), maximumDecimalPlaces);
    }

    this.PremiseHazardTypeForm = new FormGroup({
      code: new FormControl(this.SelectedPremiseHazardType?.code, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      description: new FormControl(this.SelectedPremiseHazardType?.description, []),
      directHitDistance: new FormControl(initialDirectHitDistance ?? this.unitsConverterService.fromMeters(5, this.Units), [Validators.required, greaterThanMinimumValidator(this.Units, this.unitsConverterService), lessThanMaximumValidator(this.Units, this.unitsConverterService)]),
      innerProximityDistance: new FormControl(initialInnerProximityDistance, [Validators.required, greaterThanMinimumValidator(this.Units, this.unitsConverterService), greaterThanValidator('directHitDistance'), lessThanMaximumValidator(this.Units, this.unitsConverterService)]),
      outerProximityDistance: new FormControl(initialOuterProximityDistance, [Validators.required, greaterThanMinimumValidator(this.Units, this.unitsConverterService), greaterThanValidator('innerProximityDistance'), lessThanMaximumValidator(this.Units, this.unitsConverterService)]),
      displayOrder: new FormControl(this.SelectedPremiseHazardType?.displayOrder, []),
      tearAndRunOrder: new FormControl(this.SelectedPremiseHazardType?.tearAndRunOrder, []),
      directHitAudio: new FormControl(this.SelectedPremiseHazardType?.directHitAudio, []),
      directHitVisual: new FormControl(this.SelectedPremiseHazardType?.directHitVisual, []),
      innerProximityAudio: new FormControl(this.SelectedPremiseHazardType?.innerProximityAudio, []),
      innerProximityVisual: new FormControl(this.SelectedPremiseHazardType?.innerProximityVisual, []),
      outerProximityAudio: new FormControl(this.SelectedPremiseHazardType?.outerProximityAudio, []),
      outerProximityVisual: new FormControl(this.SelectedPremiseHazardType?.outerProximityVisual, []),
    })
  }

  public cancelClicked(): void {
    this.cancelEvent.emit();
  }

  public onSubmit(): void {
    if (this.PremiseHazardTypeForm.invalid || this.IsSubmitting) {
      this.PremiseHazardTypeForm.markAllAsTouched();
      this.toastService.error('Not all required fields are valid', null, {
        autoDismiss: true,
      });
      return;
    }
    this.IsSubmitting = true;
    let premiseHazardType = this.PremiseHazardTypeForm.value;
    let premiseHazardTypeInput: PremiseHazardTypeInputModel = {
      Code: premiseHazardType.code,
      Description: premiseHazardType.description,
      DirectHitDistance: premiseHazardType.directHitDistance,
      InnerProximityDistance: premiseHazardType.innerProximityDistance,
      OuterProximityDistance: premiseHazardType.outerProximityDistance,
      DisplayOrder: premiseHazardType.displayOrder,
      TearAndRunOrder: premiseHazardType.tearAndRunOrder,
      DirectHitAudio: premiseHazardType.directHitAudio,
      DirectHitVisual: premiseHazardType.directHitVisual,
      InnerProximityAudio: premiseHazardType.innerProximityAudio,
      InnerProximityVisual: premiseHazardType.innerProximityVisual,
      OuterProximityAudio: premiseHazardType.outerProximityAudio,
      OuterProximityVisual: premiseHazardType.outerProximityVisual,
      Units: this.Units,
    };

    if (this.IsCreating) {
      this.restService.createPremiseHazardType(premiseHazardTypeInput)
        .pipe(take(1))
        .subscribe(response => {
          if (response.Item) {
            this.toastService.success('Premise Hazard Type created successfully', null, { autoDismiss: true });
          }
          this.submittedEvent.emit(response);
        })
    } else {
      this.restService.updatePremiseHazardType(this.SelectedPremiseHazardType.id, premiseHazardTypeInput, this.ETag)
        .pipe(take(1))
        .subscribe(response => {
          if (response.Item) {
            this.toastService.success('Premise Hazard Type updated successfully', null, { autoDismiss: true });
          }
          this.submittedEvent.emit(response);
        });
    }
  }

  private roundToDecimalPlaces(value: number, decimalPlaces: number): number {
    const divisor = Math.pow(10, decimalPlaces);
    const rounded = value * divisor;
    const converted = rounded.toFixed();
    return parseInt(converted) / divisor;
  }
}

export function lessThanMaximumValidator(units: Units, converterService: UnitConverterService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = parseFloat(control.value);

    if (value > converterService.fromMeters(10000, units)) {
      return { 'smallerThanMaximum': true };
    }

    return null;
  }
}

export function greaterThanMinimumValidator(units: Units, converterService: UnitConverterService): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = parseFloat(control.value);

    if (value < converterService.fromMeters(5, units)) {
      return { 'greaterThanMinimum': true };
    }

    return null;
  }
}

export function greaterThanValidator(otherControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const otherControl = control.parent?.get(otherControlName);

    if (!otherControl) {
      return null;
    }

    if (control.value <= otherControl.value) {
      return { 'greaterThan': true };
    }

    return null;
  };
}
