import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  static readonly DEFAULT_THEME_COLOR: string = '#007aaa';

  inlineStyle: any;
  themeColor: string = ThemeSwitcherService.DEFAULT_THEME_COLOR;

  constructor(@Inject(DOCUMENT) private _document: any) {}

  resetTheme() {
    const inlineStyle = this._document.querySelector(`style#custom-theme`);
    if (inlineStyle) {
      this._document.head.removeChild(inlineStyle);
      this.themeColor = ThemeSwitcherService.DEFAULT_THEME_COLOR;
    }
  }

  setTheme(isLightMode: boolean) {
    this.resetTheme();

    if (isLightMode) {
      this._document.documentElement.classList.add('light');
      this._document.documentElement.classList.remove('dark');
    } else {
      this._document.documentElement.classList.remove('light');
      this._document.documentElement.classList.add('dark');
    }
  }
}
