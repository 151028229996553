<div class="row page-container">
    <div class="col-4 view-container">
        <div class="button-header">
            <button msiTooltip="Add a new premise hazard type record" class="msi-btn msi-btn-text-and-icon" (click)="addNewPremiseHazardTypeClicked()" [disabled]="!AllowInteraction || IsEditing || isSuperAdmin || !CanBeEdited"><msi-icon name="ic_add" />Add Premise Hazard Type</button>
            <button msiTooltip="Clear all results and refresh the list" class="msi-btn msi-btn-text-and-icon" (click)="refreshClicked()" [disabled]="!AllowInteraction || IsEditing || !CanBeEdited"><msi-icon name="ic_refresh" />Refresh list</button>
        </div>
        <div class="control-container">
            <app-premise-hazard-type-list (PremiseHazardTypeClicked)="onPremiseHazardTypeSelected($event)" [changeSelectedPremiseHazardType]="changePremiseHazardTypeViewSubject" [premiseHazardTypeDeletedSubject]="premiseHazardTypeDeletedSubject" [refreshList]="refreshListSubject" [inEditMode]="IsEditing"></app-premise-hazard-type-list>
        </div>
    </div>
    <div class="col-8 view-container">
        <div class="button-header">
            <button msiTooltip="{{ SelectedPremiseHazardType ? 'Edit this premise hazard type': 'Select a premise hazard type to edit'}}" class="msi-btn msi-btn-text-and-icon" [disabled]="!AllowInteraction || !SelectedPremiseHazardType || !IsViewing || isSharedHazardType || !CanBeEdited" (click)="onEditClicked()"><msi-icon name="ic_edit" />Edit Premise Hazard Type</button>
            <button msiTooltip="{{ SelectedPremiseHazardType ? 'Delete this premise hazard type': 'Select a premise hazard type to delete'}}" class="msi-btn msi-btn-text-and-icon" [disabled]="!AllowInteraction || !SelectedPremiseHazardType || isSharedHazardType || !CanBeEdited" (click)="openModal(deleteModal)"><msi-icon name="ic_trash" />Delete Premise Hazard Type</button>
        </div>
        <div class="control-container">
            <app-premise-hazard-type-view *ngIf="IsViewing" [PremiseHazardType]="SelectedPremiseHazardType" (isSharedTypeEvent)="setSharedHazardType($event)"></app-premise-hazard-type-view>
            <app-premise-hazard-type-item *ngIf="IsEditing" (submittedEvent)="onSubmitEvent($event)" (cancelEvent)="onCancelEvent()" [SelectedPremiseHazardType]="SelectedPremiseHazardType" [ETag]="SelectedPremiseHazardTypeEtag" [Units]="SelectedUnits"></app-premise-hazard-type-item>
        </div>
    </div>
</div>

<ng-template #deleteModal let-data let-ref="modalRef">
    <msi-modal>
        <msi-modal-header class="msi-padding-s">
            <label class="msi-label">Delete Premise Hazard Type</label>
        </msi-modal-header>
        <msi-modal-content class="msi-padding-m">
            <p>Confirm deletion of {{SelectedPremiseHazardType.code}}.<br/>
                This action can <b>not</b> be reversed</p>
        </msi-modal-content>
        <msi-modal-actions align="center" class="msi-padding-s">
            <button class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="ref.close(false)">
                <msi-icon name="ic_remove" class="delete-icon"/>Cancel
            </button>
            <button class="msi-btn msi-btn-secondary msi-btn-text-and-icon" (click)="ref.close(true)">
                <msi-icon name="ic_trash"/>Delete
            </button>
        </msi-modal-actions>
    </msi-modal>
</ng-template>
