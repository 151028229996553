import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EnvService } from '../services/env.service';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  localToken: string;
  env = this.envService.env;
  env1 = this.envService.env1;
  env2 = this.envService.env2;
  domainExtension = this.envService.domainExtension;
  Jwthelper = new JwtHelperService(); // use for checking the access_token is expired or not
  token: TokenService;
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url.match(`https://api.${this.env2}commandcentral.${this.domainExtension}/api/admin`) ||
      req.url.match(`https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin`) ||
      req.url.match(`https://drive.${this.env}commandcentral.${this.domainExtension}/api/v1/items`) ||
      req.url.startsWith(`https://coregis${this.env1}commandcentral.${this.domainExtension}`)
       //|| req.url.match('https://localhost:5000')
    ) {
      const JWT = this.tokenService.retrieveToken();
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + JWT),
      });
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
  constructor(private tokenService: TokenService, private envService: EnvService) {}
}
