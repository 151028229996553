import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private sessionToken: string = null;
  retrieveToken(): string {
    return this.sessionToken;
  }
  setToken(data) {
    this.sessionToken = data;
  }
}
