<div class="msi-bg msi-margin-s">
    <div id="typeListContainer">
        <div *ngIf="IsInitialized">
            <table msi-data-table #table *ngIf="PremiseHazardTypes$ | async as PremiseHazardTypes" [condensed]="false" [pinning]="false" [rows]="PremiseHazardTypes" [separator]="true" [virtualScroll]="false" [selection]="AllowInteraction && !inEditMode" (rowClick)="premiseHazardTypeClicked($event)" [selectedRowIndex]="SelectionIndex" id="typesTable">
                <ng-container msiColDef="id" colType="number" colLock="left" [sortable]="false" [draggable]="false" [width]="4">
                    <ng-container *msiHeaderCellDef msiTooltip="The row number">Row</ng-container>
                    <ng-container *msiCellDef="let i = index; let row;">{{ i + 1 }}</ng-container>
                </ng-container>

                <ng-container msiColDef="TypeName" [sortable]="false" [draggable]="false">
                    <ng-container *msiHeaderCellDef msiTooltip="The name of the premise hazard type">Hazard Type</ng-container>
                    <ng-container *msiCellDef="let row">{{ row.code }}</ng-container>
                </ng-container>
            </table>
            <div *ngIf="!IsInitialized">
                <msi-spinner />
            </div>
        </div>
    </div>
    <div class="form-row form-row-bottom">
        <div class="col">
            <button [disabled]="!HasMoreResults || !AllowInteraction" class="msi-btn msi-btn-secondary msi-btn-text-and-icon msi-padding-s">
                <msi-icon [name]="AllowInteraction ? 'ic_download' : 'ic_refresh'"/>{{ HasMoreResults ? "Load next " + ItemsPerPage + " results" : "No More Results"}}
            </button>
        </div>
        <div class="col">
            <msi-select #itemsPerPage [value]="ItemsPerPage" (change)="onItemsPerPageChanged(itemsPerPage.value)">
                <msi-select-option *ngFor="let item of ItemsPerPageValues" [value]="item" style="overflow-x:hidden;">Max per page: {{item}}</msi-select-option>
            </msi-select>
        </div>
    </div>
</div>
