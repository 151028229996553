<div class="row msi-bg msi-margin-s item-view-container">
    <div class="col">
        <div *ngIf="PremiseHazardType; then typeView else emptyView "></div>
        <ng-template #typeView>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Name</label>
                    </div>
                    <div class="col">
                        {{ PremiseHazardType.code }}
                    </div>
                </div>
                <div *ngIf="isSharedHazardType">
                    <div class="row">
                        <div class="col">
                            <msi-callout type="info" closable="false">
                                <div calloutContent>
                                    This premise hazard type is shared from another customer or agency and can not be modified
                                </div>
                            </msi-callout>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Proximities<msi-icon name="ic_help" msiTooltip="These values are stored in meters so there may be some rounding discrepancies" /></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Direct Hit</label>
                        {{ directHitDistanceDisplay | number: NUMBER_FORMATS[selectedUnits] }} {{ UNITS_TEXT[selectedUnits] }}
                    </div>
                    <div class="col">
                        <label class="msi-label">Inner</label>
                        {{ innerProximityDistanceDisplay | number: NUMBER_FORMATS[selectedUnits] }} {{ UNITS_TEXT[selectedUnits] }}
                    </div>
                    <div class="col">
                        <label class="msi-label">Outer</label>
                        {{ outerProximityDistanceDisplay | number: NUMBER_FORMATS[selectedUnits] }} {{ UNITS_TEXT[selectedUnits] }}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Description</label>
                        {{ PremiseHazardType.description | emptyText}}
                    </div>
                </div>
                <div *ngIf="showExpandedInformation; then alertInformation"></div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">System Information</label>
                    </div>
                </div>
                <div class="msi-margin-left-m msi-margin-right-m">
                    <div class="row">
                        <div class="col msi-padding-s">
                            <label class="msi-label">Created On</label>
                            {{ PremiseHazardType.insertedTimestamp | date:dateFormat }}
                        </div>
                        <div class="col msi-padding-s" *ngIf="PremiseHazardType.updatedTimestamp">
                            <label class="msi-label">Updated On</label>
                            {{ PremiseHazardType.updatedTimestamp | date:dateFormat }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="msi-label">Display Order</label>
                            {{ PremiseHazardType.displayOrder ?? "Unset" }}
                        </div>
                        <div class="col" *ngIf="PremiseHazardType.tearAndRunOrder">
                            <label class="msi-label">Tear and Run Order</label>
                            {{ PremiseHazardType.tearAndRunOrder ?? "Unset" }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<ng-template #emptyView>
    <div class="row">
        <div class="col">
            No Premise Hazard Type Records Exist
        </div>
    </div>
</ng-template>

<ng-template #alertInformation>
    <div class="row">
        <div class="col">
            <label class="msi-label">Alert Information</label>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label class="msi-label">Direct Hit</label>
            <msi-checkbox-group class="msi-padding-s">
                <msi-checkbox [checked]="PremiseHazardType.directHitVisual" [disabled]="true">
                    Visual
                </msi-checkbox>
                <msi-checkbox [checked]="PremiseHazardType.directHitAudio" [disabled]="true">
                    Audible
                </msi-checkbox>
            </msi-checkbox-group>
        </div>
        <div class="col">
            <label class="msi-label">Inner Proximity</label>
            <msi-checkbox-group class="msi-padding-s">
                <msi-checkbox [checked]="PremiseHazardType.innerProximityVisual" [disabled]="true">
                    Visual
                </msi-checkbox>
                <msi-checkbox [checked]="PremiseHazardType.innerProximityAudio" [disabled]="true">
                    Audible
                </msi-checkbox>
            </msi-checkbox-group>
        </div>
        <div class="col">
            <label class="msi-label">Outer Proximity</label>
            <msi-checkbox-group class="msi-padding-s">
                <msi-checkbox [checked]="PremiseHazardType.outerProximityVisual" [disabled]="true">
                    Visual
                </msi-checkbox>
                <msi-checkbox [checked]="PremiseHazardType.outerProximityAudio" [disabled]="true">
                    Audible
                </msi-checkbox>
            </msi-checkbox-group>
        </div>
    </div>
</ng-template>
