import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ContentModalService, ModalService, MsiModalRef, ToastService } from "@msi/cobalt";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { finalize, take } from "rxjs";
import { PremiseHazardCommandsRestService } from "../../services/premise-hazard-command-rest.service";
import { ResponseItem } from "../../services/response-item.model";
import { RestService } from "../../services/rest.service";
import { AddressInputModel } from "../address-input.model";
import { Address } from "../address.model";
import { ContactInputModel } from "../contact-input.model";
import { DeviceInputModel } from "../device-input.model";
import { DeviceType } from "../device-type.model";
import { GeocodeInput } from "../geocode-input.model";
import { PremiseHazardInputModel } from "../premise-hazard-input.model";
import { PremiseHazardPriorityType } from "../premise-hazard-priority-type";
import { PremiseHazardType } from "../premise-hazard-type.model";
import { PremiseHazard } from "../premise-hazard.model";
import { Units } from "../units.model";

@Component({
  selector: 'app-premise-hazard-item',
  templateUrl: './premise-hazard-item.component.html',
  styleUrls: ['./premise-hazard-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PremiseHazardItemComponent implements OnInit, OnDestroy, OnChanges {
  PremiseHazardForm: FormGroup;
  @ViewChild('geocodeModal') GeocodeModal: TemplateRef<any>;
  @ViewChild('selectGeocodedAddress') selectGeocodedAddress: TemplateRef<any>;
  @ViewChild('contactFormModal') addContactForm: TemplateRef<any>;
  @ViewChild('loadingModal') loadingModal: TemplateRef<any>;
  @ViewChild('confirmRemove') ConfirmRemoveModal: TemplateRef<any>;
  GeocodeForm: FormGroup;
  ContactForm: FormGroup;
  ContactsToAdd: ContactInputModel[] = [];
  @Input() SelectedPremiseHazard: PremiseHazard;
  @Input() PremiseHazardTypes: PremiseHazardType[] = new Array<PremiseHazardType>();
  @Input() Addresses: Address[] = new Array<Address>();
  @Input() ETag: string;
  IsUpdating: boolean = false;
  IsCreating: boolean = false;
  minDate: NgbDateStruct;
  IsSubmitting: boolean = false;
  geocodeProcessing: boolean = false;
  isCreatingAddress: boolean = false;
  matchedLocations: any[] = [];
  selectedAddressCandidate: any;
  private geocodeModalRef: MsiModalRef;

  @Output() submittedEvent = new EventEmitter<ResponseItem<PremiseHazard>>();
  @Output() conflictErrorEvent = new EventEmitter<void>();
  @Output() cancelEvent = new EventEmitter<void>();

  ngOnDestroy(): void {
  }
  ngOnInit(): void {
    this.IsCreating = !(this.SelectedPremiseHazard);
    this.IsUpdating = !this.IsCreating;
    this.createPremiseHazardForm();
    this.createGeocodeForm();
    if (this.IsUpdating && this.SelectedPremiseHazard.contacts) {
      this.ContactsToAdd = this.SelectedPremiseHazard.contacts.map(contact => {
        return {
          name: contact.name,
          id: contact.id,
          devices: contact.devices?.map(device => {
            return {
              id: device.id,
              address: device.address,
              deviceType: device.deviceType,
            };
          })
        };
      });
    }
  }

  private createGeocodeForm(): void {
    this.GeocodeForm = new FormGroup({
      locationName: new FormControl<string>(null),
      address: new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(1024)]),
      city: new FormControl<string>(null),
      zipCode: new FormControl<string>(null),
      building: new FormControl<string>(null),
      apartment: new FormControl<string>(null),
      floor: new FormControl<string>(null),
      subdivision: new FormControl<string>(null),
    });
  }

  constructor(
    private commandService: PremiseHazardCommandsRestService,
    private toastService: ToastService,
    private contentModalService: ContentModalService,
    private modalService: ModalService,
    private legacyRestService: RestService,
    private cdr: ChangeDetectorRef) {
    let today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth(),
      day: today.getDay() + 1,
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  PURGE_TYPES = ['Do not purge', 'Automatically', 'Purge Manually'];
  DEFAULT_UNITS = Units.Foot;
  PRIORITY_TYPES = Object.values(PremiseHazardPriorityType).filter(val => isNaN(Number(val)));
  DEVICE_TYPES = Object.values(DeviceType).filter(val => isNaN(Number(val)));

  public cancelClicked() {
    this.cancelEvent.emit();
  }

  public onSubmit(): void {
    if (this.PremiseHazardForm.invalid || this.IsSubmitting) {
      this.PremiseHazardForm.markAllAsTouched();
      this.toastService.error('Not all required fields are valid', null, {
        autoDismiss: true,
      });
      return;
    }
    this.IsSubmitting = true;
    let premiseHazard = this.PremiseHazardForm.value;

    let premiseHazardInput = {
      AddressId: premiseHazard.addressId,
      PremiseHazardTypeId: premiseHazard.premiseHazardTypeId,
      AreaId: null,
      RecordTitle: premiseHazard.recordTitle,
      OwnerName: premiseHazard.ownerName,
      OwnerPhone: premiseHazard.ownerPhone,
      OwnerAddress: premiseHazard.ownerAddress,
      Comments: premiseHazard.comments,
      AuthorizingPersonnel: premiseHazard.authorizingPersonnel,
      PurgeDate: premiseHazard.purgeDate,
      PurgeType: premiseHazard.purgeType,
      IsTemporary: premiseHazard.isTemporary,
      IsReviewRequired: premiseHazard.isReviewRequired,
      Priority: premiseHazard.priority,
      Contacts: this.ContactsToAdd.map(contact => {
        return {
          name: contact.name,
          id: contact.id,
          devices: contact.devices.map(device => {
            return {
              id: device.id,
              address: device.address,
              deviceType: device.deviceType
            };
          })
        };
      })
    };

    if (this.isCreatingAddress) {
      const loadingModal = this.modalService.open(this.loadingModal, {
        data: { title: 'Creating Address', message: 'Do not close this window' }
      });
      const addressInputModel: AddressInputModel = {
        commonPlace: this.selectedAddressCandidate.matchLocation.commonPlace,
        apartmentNumber: this.selectedAddressCandidate.matchLocation.apartment,
        houseAddress: this.selectedAddressCandidate.matchLocation.houseAddress,
        buildingNumber: this.selectedAddressCandidate.matchLocation.building,
        city: this.selectedAddressCandidate.matchLocation.city,
        description: this.selectedAddressCandidate.matchLocation.description,
        floor: this.selectedAddressCandidate.matchLocation.floor,
        highCrossStreet: this.selectedAddressCandidate.matchLocation.highCrossStreet,
        intersection: this.selectedAddressCandidate.matchLocation.intersection,
        latitude: this.selectedAddressCandidate.matchLocation.y,
        longitude: this.selectedAddressCandidate.matchLocation.x,
        lowCrossStreet: this.selectedAddressCandidate.matchLocation.lowCrossStreet,
        postalCode: this.selectedAddressCandidate.matchLocation.zipCode,
        routeLatitude: this.selectedAddressCandidate.matchLocation.routeLatitude,
        routeLongitude: this.selectedAddressCandidate.matchLocation.routeLongitude,
        state: this.selectedAddressCandidate.matchLocation.state,
        streetType: this.selectedAddressCandidate.matchLocation.streetType,
        subdivision: this.selectedAddressCandidate.matchLocation.subdivision,
      };

      this.commandService.createAddress(addressInputModel)
        .pipe(take(1),
          finalize(() => {
            loadingModal.close();
          }))
        .subscribe((response: ResponseItem<Address>) => {
          premiseHazardInput.AddressId = response.Item.id;
          this.submitPremiseHazard(premiseHazardInput);
        });
    } else {
      this.submitPremiseHazard(premiseHazardInput);
    }
  }

  private submitPremiseHazard(premiseHazardInput: PremiseHazardInputModel): void {
    const loadingModal = this.modalService.open(this.loadingModal, {
      hasBackdrop: true,
      disableClose: true,
      data: { title: 'Saving Premise Hazard', message: 'Do not close this window' }
    });
    if (this.IsCreating) {
      this.commandService.createPremiseHazard(premiseHazardInput)
        .pipe(take(1), finalize(() => loadingModal.close()))
        .subscribe(res => {
          if (res.Item) {
            this.toastService.success('Premise Hazard created successfully', null, {
              autoDismiss: true,
            });
          }
          this.submittedEvent.emit(res);
        });
    } else {
      this.commandService.updatePremiseHazard(this.SelectedPremiseHazard.id, premiseHazardInput, this.ETag)
        .pipe(take(1), finalize(() => loadingModal.close()))
        .subscribe(res => {
          if (res.Item) {
            this.toastService.success('Premise Hazard updated successfully', null, {
              autoDismiss: true,
            });
          }
          this.submittedEvent.emit(res);
        });
    }
  }

  public onPurgeDateChanged(event: any): void {
    this.PremiseHazardForm.controls.purgeDate.setValue(new Date(event.startDate.year, event.startDate.month, event.startDate.day));
    this.cdr.detectChanges();
  }

  private createPremiseHazardForm(): void {
    this.PremiseHazardForm = new FormGroup({
      recordTitle: new FormControl<string>(this.SelectedPremiseHazard?.recordTitle, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      premiseHazardTypeId: new FormControl<string>(this.SelectedPremiseHazard?.type?.id),
      addressId: new FormControl<string>(this.SelectedPremiseHazard?.address?.id, [Validators.required]),
      ownerName: new FormControl<string>(this.SelectedPremiseHazard?.ownerName, [Validators.minLength(1), Validators.maxLength(255)]),
      ownerPhone: new FormControl<string>(this.SelectedPremiseHazard?.ownerPhone, [Validators.minLength(1), Validators.maxLength(20)]),
      ownerAddress: new FormControl<string>(this.SelectedPremiseHazard?.ownerAddress, [Validators.minLength(1), Validators.maxLength(255)]),
      comments: new FormControl<string>(this.SelectedPremiseHazard?.comments, [Validators.minLength(0), Validators.maxLength(4096)]),
      authorizingPersonnel: new FormControl<string>(this.SelectedPremiseHazard?.authorizingPersonnel, [Validators.minLength(1), Validators.maxLength(255)]),
      purgeDate: new FormControl<Date>(this.SelectedPremiseHazard?.purgeDate ?? new Date()),
      purgeType: new FormControl<string>(this.SelectedPremiseHazard?.purgeType),
      isTemporary: new FormControl<boolean>(this.SelectedPremiseHazard?.isTemporary ?? false),
      isReviewRequired: new FormControl<boolean>(this.SelectedPremiseHazard?.isReviewRequired ?? true),
      priority: new FormControl(this.SelectedPremiseHazard?.priority, [Validators.required]),
    });

    if (this.IsUpdating && this.SelectedPremiseHazard.contacts) {
      for (let contact of this.SelectedPremiseHazard.contacts) {
        this.ContactsToAdd.push({
          devices: contact.devices.map(device => {
            return {
              address: device.address,
              deviceType: device.deviceType as DeviceType,
              id: device.id,
            };
          }),
          name: contact.name,
          id: contact.id,
        });
      }
    }
    this.cdr.detectChanges();
  }

  public openContactModal(): void {
    this.ContactForm = new FormGroup({
      name: new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      devices: new FormArray([]),
    });
    const contactModal = this.modalService.open(this.addContactForm, {
      hasBackdrop: true,
      disableClose: true,
    });
    contactModal
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }
        if (this.ContactForm.valid) {
          let contact = this.ContactForm.value;
          let devices = contact.devices;
          let devicesToAdd: DeviceInputModel[] = [];

          for (let device of devices) {
            devicesToAdd.push({ address: device.address, deviceType: device.deviceType })
          }

          let contactInputModel: ContactInputModel = {
            name: contact.name,
            devices: devicesToAdd,
          }
          this.ContactsToAdd.push(contactInputModel);
          this.cdr.detectChanges();
        }
      });
  }

  public addDeviceToContactModal(): void {
    let deviceArray = this.ContactForm.controls.devices as FormArray;
    deviceArray.push(new FormGroup({
      address: new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      deviceType: new FormControl<DeviceType>(null, [Validators.required]),
      id: new FormControl<string>(null),
    }));
    this.cdr.detectChanges();
  }

  public removeDeviceFromContactModal(index: number): void {
    let deviceArray = this.ContactForm.controls.devices as FormArray;
    if (index < 0 || index >= deviceArray.length) {
      return;
    }

    const confirmModal = this.modalService.open(this.ConfirmRemoveModal, {
      hasBackdrop: true, disableClose: true, data: {
        'title': 'Remove Device',
        'message': 'Delete this device?'
      }, size: 'small'
    });

    confirmModal.afterClosed().pipe(take(1)).subscribe((result: boolean) => {
      if (!result) {
        return;
      }
      deviceArray.removeAt(index);
      this.ContactForm.updateValueAndValidity();
      this.cdr.detectChanges();
    });
  }

  public removeContact(index: number): void {
    if (index < 0 || index >= this.ContactsToAdd.length) {
      return;
    }
    const confirmModal = this.modalService.open(this.ConfirmRemoveModal, {
      hasBackdrop: true, disableClose: true, data: {
        'title': 'Remove Contact',
        'message': 'Delete this contact?'
      }, size: 'small'
    });

    confirmModal.afterClosed().pipe(take(1)).subscribe((result: boolean) => {
      if (!result) {
        return;
      }
      this.ContactsToAdd.splice(index, 1);
      this.cdr.detectChanges();
    });
  }

  public removeDeviceFromContact(contactIdx: number, deviceIdx: number): void {
    if (contactIdx < 0 || contactIdx >= this.ContactsToAdd.length) {
      return;
    }
    const contact = this.ContactsToAdd[contactIdx];

    if (deviceIdx < 0 || deviceIdx >= contact.devices.length) {
      return;
    }

    const confirmModal = this.modalService.open(this.ConfirmRemoveModal, {
      hasBackdrop: true, disableClose: true, data: {
        'title': 'Remove Device',
        'message': 'Delete this device?'
      }, size: 'small'
    });

    confirmModal.afterClosed().pipe(take(1)).subscribe((result: boolean) => {
      if (!result) {
        return;
      }
      this.ContactsToAdd[contactIdx].devices.splice(deviceIdx, 1);
      this.cdr.detectChanges();

    });
  }

  public trackByContactIndex(index: number, item: any): number {
    return index;
  }

  public trackByDeviceIndex(index: number, item: any): number {
    return index;
  }

  public openGeocodeModal(): void {
    this.GeocodeForm.reset();

    this.geocodeModalRef = this.modalService.open(this.GeocodeModal, {
      hasBackdrop: true,
      disableClose: true,
      size: 'medium',
    });
  }

  public onSubmitGeocodeForm(): void {
    if (!this.GeocodeForm.valid) {
      return;
    }
    this.geocodeProcessing = true;
    const form = this.GeocodeForm;

    const addressInput: GeocodeInput = {
      address: form.controls.address.value,
      city: form.controls.city.value,
      zipCode: form.controls.zipCode.value,
      building: form.controls.building.value,
      apartment: form.controls.apartment.value,
      floor: form.controls.floor.value,
      subdivision: form.controls.subdivision.value,
      locationName: form.controls.locationName.value,
    };

    this.legacyRestService.callGeoverfication(addressInput)
      .pipe(take(1), finalize(() => this.geocodeProcessing = false))
      .subscribe((response: any) => {
        this.geocodeModalRef.close();
        this.matchedLocations = response.filter(this.filterValidLocations);
        if (!this.matchedLocations || this.matchedLocations.length === 0) {
          this.toastService.warning("No valid locations found", null, {
            autoDismiss: true,
          });
          return;
        }
        const contentModal = this.contentModalService.open(this.selectGeocodedAddress, {
          data: { selectedMediaIndex: 0 },
        });
        contentModal
          .afterClosed()
          .pipe(take(1))
          .subscribe((result: number) => {
            this.geocodeProcessing = false;
            if (result === undefined || result === null) {
              this.toastService.warning("No location was selected", null, {
                autoDismiss: 5000,
              });
              return;
            }
            if (result < 0 || result >= this.matchedLocations.length) {
              this.toastService.error("No valid location selected", null, {
                autoDismiss: 10000,
              });
              return;
            }
            this.isCreatingAddress = true;
            this.selectedAddressCandidate = this.matchedLocations[result];
            this.PremiseHazardForm.controls['addressId'].clearValidators();
            this.PremiseHazardForm.controls['addressId'].setValue(null);
            this.PremiseHazardForm.controls['addressId'].updateValueAndValidity();
            this.cdr.detectChanges();
          });
      });
  }

  private filterValidLocations(element, index, array): boolean {
    return element.matchLocation.x && element.matchLocation.y;
  }

  public openModal(templateRef: TemplateRef<boolean | null>): void {
    const modalRef = this.modalService.open(templateRef, {
      disableClose: true,
      hasBackdrop: true,
    });

    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.isCreatingAddress = false;

          this.PremiseHazardForm.controls['addressId'].addValidators([Validators.required]);
          this.PremiseHazardForm.controls['addressId'].setValue(null);
          this.PremiseHazardForm.controls['addressId'].updateValueAndValidity();
          this.cdr.detectChanges();
        }
      });
  }

  public onSelectAddress(index: number): void {
    if (index < 0 || index >= this.matchedLocations.length) {
      this.toastService.error("No valid location selected", null, {
        autoDismiss: 10000
      });
      return;
    }
    this.selectedAddressCandidate = this.matchedLocations[index];
  }

  public onCancelGeocodeForm(): void {
    this.geocodeModalRef.close();
  }
}
