import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastService } from '@msi/cobalt';
import { HeaderTokenService } from '@msi/commandcentral-user-authentication';
import { Subscription, of } from 'rxjs';
import { catchError, filter, finalize, take } from 'rxjs/operators';
import { AdminRestService } from './services/admin-rest.service';
import { EnvService } from './services/env.service';
import { PremiseHazardRestBase } from './services/premise-hazard-rest-base';
import { RestService } from './services/rest.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { ThemeSwitcherService } from './theme-switcher.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, OnChanges {
  title = 'gis-prh-ui';
  user;
  clientId;
  customer;
  agency;
  scopes;
  customerId;
  roleSystemName;
  groupSystemName;
  agencyId;
  token;
  admin;
  cloud;
  isParentAgencyAdminUser = false;
  currentAgencyRoot: FormControl;
  agencyList = [];
  GetMeLoader: Subscription;
  GetMeDone = false;
  selectedCustomerId: string = null;
  CCAdminVersion = this.env.admin;
  THEME_TEXTS: string[] = ['Light Mode', 'Dark Mode'];
  lightMode: boolean = false;
  ThemeText: string = this.THEME_TEXTS[this.lightMode ? 0 : 1];
  public appReady: boolean = false;

  ShowThemeToggle: boolean = true;
  constructor(
    private rest: RestService,
    private toastService: ToastService,
    private headerTokenService: HeaderTokenService,
    private route: ActivatedRoute,
    private storedToken: TokenService,
    private env: EnvService,
    private themeSwitcherService: ThemeSwitcherService,
    private cdr: ChangeDetectorRef,
    private phzRest: PremiseHazardRestBase,
    private userService: UserService,
    private adminRest: AdminRestService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const params = this.route.snapshot.firstChild?.queryParamMap;
      if (params.has('theme')) {
        const theme = params.get('theme');
        const isLightMode = theme.toLocaleLowerCase().localeCompare('light') === 0;
        this.ShowThemeToggle = false;
        this.themeSwitcherService.setTheme(isLightMode);
      }
      if (params.has('customerId')) {
        this.customerId = params.get('customerId');
      }
      if (params.has('agencyId')) {
        this.agencyId = params.get('agencyId');
      }
      if (params.has('selectedCustomerId')) {
        this.selectedCustomerId = params.get('selectedCustomerId');
      }
      this.initializeToken();
    });
    this.env.SetAdmin(undefined);
  }

  private initializeToken(): void {
    // to get tokenReady value, confirmAuthGuardService must be added to routes, see app-routing.module.ts
    this.headerTokenService.tokenReady.pipe(take(1)).subscribe((isTokenReady) => {
      if (isTokenReady) {
        const texttoken = this.headerTokenService.getTokenSync();
        this.token = texttoken;
        this.storedToken.setToken(this.token);
        const token = this.encodeToken(texttoken);
        this.user = token.sub;
        sessionStorage.setItem('user', this.user);
        this.clientId = token.client_id;
        this.customer = token.customer;
        sessionStorage.setItem('customer', this.customer);
        this.agency = token.agency;
        sessionStorage.setItem('agency', this.agency);
        this.scopes = token.scope;
        // Make sure the customer is initialized and sharing exists
        this.adminRest.initializeCustomer().pipe(take(1)).subscribe(() => {
        });
        // call getme
        this.GetMeLoader = this.rest
          .getMe()
          .pipe(take(1), catchError(err => { return null; }))
          .subscribe(
            (data) => {
              if (this.GetMeDone === false) {
                this.GetMeDone = true;
                let userAgency: string = '';
                let userCustomer: string = '';
                let isSuperAdmin: boolean = false;

                if (this.CCAdminVersion === 'admin2.') {
                  let val = sessionStorage.getItem('customer');
                  if (data.me.currentAgency !== undefined) {
                    val = data.me.currentAgency;
                  }
                  userAgency = val;
                  userCustomer = val;
                  isSuperAdmin = true;
                }
                else if (data.impersonatingUser !== undefined) {
                  userAgency = data._links.agency.agencyId;
                  userCustomer = data._links.customer.customerId;
                  isSuperAdmin = true;
                } else {
                  userAgency = this.agency;
                  userCustomer = this.customer;
                }
                this.rest.changeAgency(userAgency);
                this.rest.changeCustomer(userCustomer);
                this.rest.CheckIfIsSuperAdmin(isSuperAdmin);
                if (userAgency !== this.selectedCustomerId && userCustomer !== this.selectedCustomerId) {
                  if (userCustomer.localeCompare('motorola-solutions', undefined, { caseFirst: 'lower' }) === 0 &&
                    userAgency.localeCompare('motorola-solutions', undefined, { caseFirst: 'lower' }) === 0) {
                    isSuperAdmin = true;
                    this.toastService.information('You are currently logged in as a super admin and will not be able to modify information', null, {
                      autoDismiss: 60000,
                      closeButton: true,
                    });
                  }
                }

                this.userService.setUserInformation({
                  customerId: userCustomer,
                  agencyId: userAgency,
                  isSuperAdmin: isSuperAdmin,
                  token: this.token,
                  selectedCustomerId: this.selectedCustomerId,
                  user: this.user,
                });
                this.rest.changeUserStatus(true);

                if (this.CCAdminVersion === 'admin2.') {
                  this.setAdmin2Data(data, userCustomer, userAgency);
                }
                else {
                  this.setAdminData(data, userCustomer, userAgency);
                }
              }
            }
          );
      }
    });
  }

  private setAdminData(data: any, userCustomer: string, userAgency: string): void {
    const phzName = 'phzProvisioning';
    for (const permission of data.services[phzName].permissions) {
      if (permission.systemName === 'ProvisionSubAgencies' && userCustomer === userAgency) {
        this.rest
          .getallagencies(userCustomer)
          .pipe(take(1),
            finalize(() => { this.appReady = true; this.cdr.detectChanges(); }),
            catchError(err => {
              this.toastService.error('Fail to get agencies of this customer - ' + err.status, null, {
                autoDismiss: 10000,
                closeButton: true,
              });
              return of(false);
            }))
          .subscribe((response: any) => {
            if (!response) {
              return;
            }
            const res = response;
            const agencyList = (res as any)._embedded.item;
            for (const agency of agencyList) {
              this.agencyList.push(agency.id);
            }
            this.isParentAgencyAdminUser = true;
            this.currentAgencyRoot = new FormControl(userAgency);
            this.cdr.detectChanges();
          });
        break;
      }
    }
  }

  private setAdmin2Data(data: any, userCustomer: string, userAgency: string): void {
    for (const permission of data.me.permissions) {
      if (permission.serviceSystemName === 'ProvisionSubAgencies' && userCustomer === userAgency) {
        this.rest
          .getallagencies(userCustomer)
          .pipe(take(1),
            finalize(() => { this.appReady = true; this.cdr.detectChanges(); }),
            catchError(err => {
              this.toastService.error('Fail to get agencies of this customer - ' + err.status, null, {
                autoDismiss: 10000,
                closeButton: true,
              });
              return null;
            }))
          .subscribe(response => {
            const res = response;
            const agencyList = (res as any)._embedded.item;
            for (const agency of agencyList) {
              this.agencyList.push(agency.id);
            }
            this.isParentAgencyAdminUser = true;
            this.currentAgencyRoot = new FormControl(userAgency);
            this.cdr.detectChanges();
          });
        break;
      }
    };
  }

  ngOnDestroy(): void {
    if (this.GetMeLoader !== undefined) {
      this.GetMeLoader.unsubscribe();
    }
  }
  ngOnChanges(): void {
    this.currentAgencyRoot.valueChanges.subscribe((val) => {
      this.rest.changeAgency(this.currentAgencyRoot.value);
    });
  }

  private encodeToken(token: string) {
    const payloadBase64 = token.split('.')[1];
    const payloadStr = atob(payloadBase64);
    return JSON.parse(payloadStr);
  }

  public onThemeToggle(event: any): void {
    this.lightMode = !this.lightMode;
    if (this.lightMode) {
      this.ThemeText = this.THEME_TEXTS[0];
    } else {
      this.ThemeText = this.THEME_TEXTS[1];
    }
    this.themeSwitcherService.setTheme(this.lightMode);
  }
}
