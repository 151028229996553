<div class="edit-container">
    <h3><label class="msi-label msi-margin-s">{{ IsCreating ? "New Premise Hazard Type" : "Modifying Premise Hazard Type" }}</label></h3>
    <form [formGroup]="PremiseHazardTypeForm" (ngSubmit)="onSubmit()" (disabled)="IsSubmitting" class="msi-margin-m">
        <div class="row">
            <div class="col">
                <label class="msi-label">Code</label> <input msiInput class="msi-margin-s" placeholder="Premise Hazard Type Code" formControlName="code" />
                <div *ngIf="!PremiseHazardTypeForm.controls['code'].valid && PremiseHazardTypeForm.controls['code'].touched">
                    <div style="color: red;">Field is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="msi-label">Description</label>
                <textarea msiInput class="msi-textarea msi-margin-s" placeholder="Premise Hazard Type Description" formControlName="description" style="margin-left: 20px; width: 70%;"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="msi-label"><msi-icon name="ic_help" msiTooltip="These values are stored in meters so there may be some rounding discrepancies" />Proximities</label>
            </div>
        </div>
        <div class="row" style="flex-direction: row;">
            <div class="col">
                <label class="msi-label">Direct Hit Distance</label>
                <input msiInput class="msi-margin-s" placeholder="Direct Hit Distance" formControlName="directHitDistance" type="number" /> {{ UNITS_TEXT[Units] }}
                <div *ngIf="!PremiseHazardTypeForm.controls['directHitDistance'].valid">
                    <div style="color: red;">{{ DirectHitDistanceErrorText }}</div>
                </div>
            </div>
            <div class="col">
                <label class="msi-label">Inner Proximity</label>
                <input msiInput class="msi-margin-s" placeholder="Inner Proximity Distance" formControlName="innerProximityDistance" type="number" /> {{ UNITS_TEXT[Units] }}
                <div *ngIf="!PremiseHazardTypeForm.controls['innerProximityDistance'].valid && PremiseHazardTypeForm.controls['innerProximityDistance'].touched">
                    <div style="color: red;">Inner Proximity Distance must be greater than Direct Hit Distance</div>
                </div>
            </div>
            <div class="col">
                <label class="msi-label">Outer Proximity</label>
                <input msiInput class="msi-margin-s" placeholder="Outer Proximity Distance" formControlName="outerProximityDistance" type="number" /> {{ UNITS_TEXT[Units] }}
                <div *ngIf="!PremiseHazardTypeForm.controls['outerProximityDistance'].valid  && PremiseHazardTypeForm.controls['outerProximityDistance'].touched">
                    <div style="color: red;">Outer Proximity Distance must be greater than Inner Proximity Distance</div>
                </div>
            </div>
        </div>
        <div>
            <label class="msi-label">Display Order</label>
            <input msiInput class="msi-margin-s" placeholder="Display Order" formControlName="displayOrder" type="number" />
            <label class="msi-label">Tear and Run Order</label>
            <input msiInput class="msi-margin-s" placeholder="Tear and Run Order" formControlName="tearAndRunOrder" type="number" />
        </div>
        <div>
            <label class="msi-label">
                CAD Cloud Alerting
            </label>
            <div class="row">
                <div class="col">
                    Direct Hit
                    <msi-checkbox-group>
                        <msi-checkbox label="Visual" [checked]="PremiseHazardTypeForm.controls.directHitVisual.value" formControlName="directHitVisual">Visual</msi-checkbox>
                        <msi-checkbox label="Audio" [checked]="PremiseHazardTypeForm.controls.directHitAudio.value" formControlName="directHitAudio">Audible</msi-checkbox>
                    </msi-checkbox-group>
                </div>
                <div class="col">
                    Inner Proximity
                    <msi-checkbox-group>
                        <msi-checkbox label="Visual" [checked]="PremiseHazardTypeForm.controls.innerProximityVisual.value" formControlName="innerProximityVisual">Visual</msi-checkbox>
                        <msi-checkbox label="Audio" [checked]="PremiseHazardTypeForm.controls.innerProximityAudio.value" formControlName="innerProximityAudio">Audible</msi-checkbox>
                    </msi-checkbox-group>
                </div>
                <div class="col">
                    Outer Proximity
                    <msi-checkbox-group>
                        <msi-checkbox label="Visual" [checked]="PremiseHazardTypeForm.controls.outerProximityVisual.value" formControlName="outerProximityVisual">Visual</msi-checkbox>
                        <msi-checkbox label="Audio" [checked]="PremiseHazardTypeForm.controls.outerProximityAudio.value" formControlName="outerProximityAudio">Audible</msi-checkbox>
                    </msi-checkbox-group>
                </div>
            </div>
        </div>
        <div class="form-row justify-content-between" style="display: flex; flex-wrap: nowrap">
            <div class="col">
                <button class="msi-btn msi-btn-text-and-icon" type="submit" [disabled]="IsSubmitting"><msi-icon name="ic_add" />{{ IsCreating ? "Add" : "Update" }} Premise Hazard Type</button>
            </div>
            <div class="col">
                <button class="msi-btn msi-btn-text-and-icon" (click)="cancelClicked()" [disabled]="IsSubmitting">
                    <msi-icon name="ic_remove" class="delete-icon" />Cancel
                </button>
            </div>
        </div>
    </form>
</div>
