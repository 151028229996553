<div class="msi-bg msi-margin-s">
    <div id="hazardListContainer">
        <div *ngIf="IsInitialized">
            <table msi-data-table #table *ngIf="PremiseHazards$ | async as PremiseHazards" [condensed]="false" [pinning]="false" [rows]="PremiseHazards" [separator]="true" [virtualScroll]="false" [selection]="AllowInteraction && !inEditMode" (rowClick)="premiseHazardClicked($event)" [selectedRowIndex]="SelectionIndex" id="hazardTable">
                <ng-container msiColDef="id"
                              colType="number"
                              colLock="left"
                              [sortable]="false"
                              [draggable]="false">
                    <ng-container *msiHeaderCellDef msiTooltip="The row number" width="10%">Row</ng-container>
                    <ng-container *msiCellDef="let i = index; let row;">{{ i + 1 }}<msi-icon msiTooltip="This premise hazard does not have an address record associated and will only be visible in this UI. An Address should be added so that it will be visible outside of this UI." name="ic_error" *ngIf="!row.address || !row.address?.id" color="#cc0000"/></ng-container>
                </ng-container>
                <ng-container msiColDef="RecordName"
                              [sortable]="false"
                              [draggable]="false">
                    <ng-container *msiHeaderCellDef msiTooltip="The name of the premise hazard record">
                        Name
                    </ng-container>
                    <ng-container *msiCellDef="let row">{{ row.recordTitle }}</ng-container>

                </ng-container>
                <ng-container msiColDef="TypeName"
                              [sortable]="false"
                              [draggable]="false">
                    <ng-container *msiHeaderCellDef msiTooltip="The name of the premise hazard type">
                        Hazard Type
                    </ng-container>
                    <ng-container *msiCellDef="let row">
                        <div *ngIf="row.type?.code">{{row.type.code}}</div>
                        <div *ngIf="!row.type?.code" class="justify-content-right"><msi-icon name="ic_warning" msiTooltip="This premise hazard is not associated with a type record and will not show up in proximity queries" color="#eed202"/>No type</div>
                    </ng-container>
                </ng-container>
            </table>
            <div *ngIf="!IsInitialized">
                <msi-spinner></msi-spinner>
            </div>
        </div>
    </div>
    <div class="form-row form-row-bottom">
        <div class="col">
            <button [disabled]="!HasMoreResults || !AllowInteraction" class="msi-btn msi-btn-secondary msi-btn-text-and-icon msi-padding-s" (click)="loadNextPageClicked()"><msi-icon [name]="AllowInteraction ? 'ic_download' : 'ic_refresh'" />{{ HasMoreResults ? "Load next " + ItemsPerPage + " results" : "No More Results"}}</button>
        </div>
        <div class="col">
            <msi-select #itemsPerPage [value]="ItemsPerPage" (change)="onItemsPerPageChanged(itemsPerPage.value)">
                <msi-select-option *ngFor="let item of ItemsPerPageValues" class="items-per-page" [value]="item">Max per page: {{item}}</msi-select-option>
            </msi-select>
        </div>
    </div>
</div>
