/*
 * Copyright 2020 Motorola Solutions, Inc.
 * All Rights Reserved.
 * Motorola Solutions Confidential Restricted
 */

import { IAM_ENVS, IamAuthGrantConfig } from '@msi/commandcentral-user-authentication';

const CLIENT_ID = 'CCAdmin';
const SCOPES = ['CCAdmin', 'openid', 'profile', 'email', 'CCRuleService'];

export function getAppSpecificIamConfig() {
  const origin = window.origin;
  const host = new URL(origin).host;
  let type;
  if (host.includes('localhost')) {
    type = 'local';
  } else {
    type = host.split('.')[1];
  }
  let IDM;
  const parent = document.referrer;
  if (parent.includes('admin2')) {
      sessionStorage.setItem('Admin', 'admin2');
  }
  switch (type) {
    case 'local':
    case 'dev':
          IDM = IAM_ENVS.IDM_MASTER;
      break;
    case 'stage':
    case 'usgov-stage':
          IDM = IAM_ENVS.IDM_MASTER;
      break;
    case 'commandcentral':
    case 'ent':
    case 'prod':
      if(host.includes('.commandcentral.ca'))
      {
          IDM = IAM_ENVS.IDM_CA;
      }
      else if (host.includes('.commandcentral.net.au'))
      {
          IDM = IAM_ENVS.IDM_AU;
      }
      else
      {
          IDM = IAM_ENVS.IDM_US;
      }
      break;
    case 'usgov':
      IDM = IAM_ENVS.IDM_US;
      break;
      case 'sec':
          IDM = IAM_ENVS.IDM_SEC_CA;
          if (host.includes('preprod')) {
              IDM = IAM_ENVS.IDM_PRE_PROD_SEC_CA;
            }
          break;
    case 'fed':
        IDM = IAM_ENVS.IDM_FED_PROD;
        if (host.includes('stage')) {
            IDM = IAM_ENVS.IDM_FED_STAGE;
            }
        break;
    default:
      IDM = IAM_ENVS.IDM_US;
      break;
  }
  const config: IamAuthGrantConfig = {
    scopes: SCOPES,
    clientId: CLIENT_ID,
    developmentMode: false,
    singlePreprod: true,
    idmUrl: IDM,
  };
  return config;
}
