import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ModalService } from '@msi/cobalt';
import { Subject, Subscription, take } from 'rxjs';
import { PremiseHazardType } from '../../premise-hazards/premise-hazard-type.model';
import { Units } from '../../premise-hazards/units.model';
import { PremiseHazardCommandsRestService } from '../../services/premise-hazard-command-rest.service';
import { PremiseHazardQueryRestService } from '../../services/premise-hazard-rest.service';
import { ResponseItem } from '../../services/response-item.model';
import { AdminRestService } from '../../services/admin-rest.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-premise-hazard-type-page',
  templateUrl: './premise-hazard-type-page.component.html',
  styleUrls: ['./premise-hazard-type-page.component.scss'],
  providers: [ModalService],
})
export class PremiseHazardTypePageComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  constructor(
    private restService: PremiseHazardQueryRestService,
    private commandService: PremiseHazardCommandsRestService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private adminApi: AdminRestService) { }

  SelectedPremiseHazardType: PremiseHazardType;
  SelectedPremiseHazardTypeEtag: string;
  IsViewing: boolean = true;
  IsEditing: boolean = false;
  AllowInteraction: boolean = false;
  SelectedUnits: Units = Units.Foot;
  public isSuperAdmin: boolean = false;
  CanBeEdited: boolean = false;

  changePremiseHazardTypeViewSubject: Subject<PremiseHazardType> = new Subject<PremiseHazardType>();
  premiseHazardTypeDeletedSubject: Subject<PremiseHazardType> = new Subject<PremiseHazardType>();
  refreshListSubject: Subject<void> = new Subject<void>();
  userServiceSubscription: Subscription = this.userService.UserInformation.subscribe(userInfo => {
    if (!userInfo) {
      return;
    }
    this.isSuperAdmin = userInfo.isSuperAdmin;
  });
  public isSharedHazardType: boolean = false;

  openModal(templateRef: TemplateRef<boolean | null>): void {
    const modalRef = this.modalService.open(templateRef, {
      disableClose: true,
      hasBackdrop: true,
    });

    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.deletePremiseHazardType();
        }
      });
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(): void {
    if (this.SelectedPremiseHazardType) {
      this.restService.getPremiseHazardType(this.SelectedPremiseHazardType.id)
        .pipe(take(1))
        .subscribe(response => {
          this.SelectedPremiseHazardType = response.Item;
          this.SelectedPremiseHazardTypeEtag = response.ETag;
        });
    }
  }

  ngOnDestroy(): void {
    this.userServiceSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.AllowInteraction = true;
    this.adminApi.getCustomersConfiguration().pipe(take(1))
    .subscribe((data) => {
      this.CanBeEdited = data.Item;
    });
  }

  public onPremiseHazardTypeSelected(premiseHazardType: PremiseHazardType): void {
    if (this.IsViewing) {
      this.SelectedPremiseHazardType = premiseHazardType;
    }
  }

  public addNewPremiseHazardTypeClicked(): void {
    this.IsViewing = false;
    this.IsEditing = true;
    this.SelectedPremiseHazardType = null;
  }

  public onEditClicked(): void {
    if (!this.SelectedPremiseHazardType) {
      return;
    }
    this.AllowInteraction = false;
    this.restService.getPremiseHazardType(this.SelectedPremiseHazardType.id)
      .pipe(take(1))
      .subscribe(response => {
        if (response.Item) {
          this.SelectedPremiseHazardType = response.Item;
          this.SelectedPremiseHazardTypeEtag = response.ETag;
          this.IsViewing = false;
          this.IsEditing = true;
          this.AllowInteraction = true;
        }
      });
  }

  public refreshClicked(): void {
    this.refreshListSubject.next();
  }

  private deletePremiseHazardType(): void {
    if (!this.SelectedPremiseHazardType) {
      return;
    }
    this.AllowInteraction = false;
    this.commandService.deletePremiseHazardType(this.SelectedPremiseHazardType.id, this.SelectedPremiseHazardTypeEtag)
      .pipe(take(1))
      .subscribe(response => {
        if (response.Item) {
          this.premiseHazardTypeDeletedSubject.next(response.Item);
        }
        this.AllowInteraction = true;
      });
  }

  public onSubmitEvent(response: ResponseItem<PremiseHazardType>): void {
    if (response) {
      this.SelectedPremiseHazardType = response.Item;
    }
    this.IsViewing = true;
    this.IsEditing = false;
    this.changePremiseHazardTypeViewSubject.next(response.Item);
    this.SelectedPremiseHazardTypeEtag = response.ETag;
  }

  public onCancelEvent(): void {
    this.IsViewing = true;
    this.IsEditing = false;
    this.changePremiseHazardTypeViewSubject.next(null);
  }

  public setSharedHazardType(event: any): void {
    this.isSharedHazardType = event;
    this.AllowInteraction = true;
    this.cdr.detectChanges();
  }
}
