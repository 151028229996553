import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { PremiseHazardsPageComponent } from './premise-hazards/premise-hazard-page/premise-hazard-page.component';
import { PremiseHazardTypePageComponent } from './premise-hazard-type/premise-hazard-type-page/premise-hazard-type-page.component';
import { DataSharingComponent } from './data-sharing/data-sharing.component';

const routes: Routes = [
  {
    path: '',
    component: PremiseHazardsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'types',
    component: PremiseHazardTypePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sharing',
    component: DataSharingComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
