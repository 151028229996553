<div class="container-fluid light" style="overflow-y: hidden;">
    <div *ngIf="!appReady; then notReady else ready;"></div>
</div>

<ng-template #notReady>
    <div>
        <msi-spinner size="large" />
    </div>
</ng-template>

<ng-template #ready>
    <div class="row" style="display: flex; flex-direction: row;" *ngIf="appReady">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <msi-sidenav class="sidenav" #sidenav role="navigation" [collapsable]="true">
                <msi-item routerLink="">Hazards</msi-item>
                <msi-item routerLink="/types">Hazard Types</msi-item>
                <msi-item routerLink="/sharing">Data Sharing</msi-item>
                <msi-toggle [checked]="!lightMode" (change)="onThemeToggle($event)" [text]="ThemeText" [hidden]="!ShowThemeToggle"></msi-toggle>
            </msi-sidenav>
        </div>
        <div class="col-sm-10 col-md-10 col-lg-10 col-lg-10">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-template>
