import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'emptyText',
})
export class EmptyTextPipe implements PipeTransform {
  private UNSET_TEXT: string = "Not Set";
  transform(value: string, defaultText?: string | null): string {
    if (!value) {
      return defaultText ?? this.UNSET_TEXT;
    }
    if (value.length === 0) {
      return defaultText ?? this.UNSET_TEXT;
    }
    return value;
  }
}
