import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { AddressInputModel } from "../premise-hazards/address-input.model";
import { Address } from "../premise-hazards/address.model";
import { AttachmentInputModel } from "../premise-hazards/attachment-input.model";
import { PremiseHazardInputModel } from "../premise-hazards/premise-hazard-input.model";
import { PremiseHazardTypeInputModel } from "../premise-hazards/premise-hazard-type-input.model";
import { PremiseHazardType } from "../premise-hazards/premise-hazard-type.model";
import { PremiseHazard } from "../premise-hazards/premise-hazard.model";
import { PremiseHazardRestBase } from "./premise-hazard-rest-base";
import { ResponseItem } from "./response-item.model";

@Injectable({
  providedIn: 'root',
})

export class PremiseHazardCommandsRestService extends PremiseHazardRestBase {
  public createPremiseHazard(premiseHazard: PremiseHazardInputModel): Observable<ResponseItem<PremiseHazard>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response'
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/`;
    return this.httpClient.post<PremiseHazard>(url, premiseHazard, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map(response => {
          const result: ResponseItem<PremiseHazard> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          }
          return result;
        })
      );
  }

  public updatePremiseHazard(id: string, premiseHazard: PremiseHazardInputModel, etag: string): Observable<ResponseItem<PremiseHazard>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'If-Match': etag
      }),
      observe: 'response' as 'response'
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/${id}`;
    return this.httpClient.put<PremiseHazard>(url, premiseHazard, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map(response => {
          const result: ResponseItem<PremiseHazard> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          }
          return result;
        })
      );
  }

  public deletePremiseHazard(id: string, etag: string): Observable<any> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'If-Match': etag
      })
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/${id}`;
    return this.httpClient.delete(url, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        })
      )
  }

  public createPremiseHazardType(type: PremiseHazardTypeInputModel): Observable<ResponseItem<PremiseHazardType>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response'
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/types`;
    return this.httpClient.post<PremiseHazardType>(url, type, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map(response => {
          const result: ResponseItem<PremiseHazardType> = {
            ETag: response.headers.get('ETag'),
            Item: response.body,
          };
          return result;
        }),
      );
  }

  public updatePremiseHazardType(id: string, type: PremiseHazardTypeInputModel, etag: string): Observable<ResponseItem<PremiseHazardType>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'if-match': etag,
      }),
      observe: 'response' as 'response'
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/types/${id}`;
    return this.httpClient.put<PremiseHazardType>(url, type, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map(response => {
          const result: ResponseItem<PremiseHazardType> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          };
          return result;
        }),
      );
  }

  public deletePremiseHazardType(id: string, etag: string): Observable<any> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'If-Match': etag
      })
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/types/${id}`;
    return this.httpClient.delete(url, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        })
      );
  }

  public createAddress(address: AddressInputModel): Observable<ResponseItem<Address>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
      }),
      observe: 'response' as 'response'
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/addresses`;
    return this.httpClient.post<Address>(url, address, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map(response => {
          const result: ResponseItem<Address> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          };
          return result;
        }),
      );
  }

  public updateAddress(id: string, address: AddressInputModel, etag: string): Observable<ResponseItem<Address>> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'if-match': etag,
      }),
      observe: 'response' as 'response'
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/addresses/${id}`;
    return this.httpClient.put<Address>(url, address, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        }),
        map(response => {
          const result: ResponseItem<Address> = {
            Item: response.body,
            ETag: response.headers.get('ETag'),
          };
          return result;
        }),
      );
  }

  public deleteAddress(id: string, etag: string): Observable<any> {
    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
        'content-type': 'application/json',
        'If-Match': etag
      })
    };

    let url = `${this.phzBaseUrl}${this.commandUrl}${this.addCustomerRoute()}/addresses/${id}`;
    return this.httpClient.delete(url, opt)
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            let httpError = err as HttpErrorResponse;
            this.handleHttpError(httpError);
          }
          return throwError(() => err);
        })
      );
  }

  public uploadAttachment(premiseHazardId: string, commandModel: AttachmentInputModel): Observable<any> {
    const payload = new FormData();
    payload.append('file', commandModel.file);
    payload.append('attachmentName', commandModel.name);
    payload.append('attachmentDescription', commandModel.description);

    const opt = {
      headers: new HttpHeaders({
        'MsiProduct': this.MsiProduct,
      })
    };

    return this.httpClient
      .post(`${this.phzBaseUrl}${this.commandUrl}/${premiseHazardId}/attachments`, payload, opt)
      .pipe(catchError(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          let httpError = err as HttpErrorResponse;
          this.handleHttpError(httpError);
        }
        return throwError(() => err);
      })));
  }

  public deleteAttachment(premiseHazardId: string, attachmentId: string): Observable<any> {
    const opt = {
      headers: new HttpHeaders({
        msiProduct: this.MsiProduct,
      })
    };

    return this.httpClient
      .delete(`${this.phzBaseUrl}${this.commandUrl}/${premiseHazardId}/attachments/${attachmentId}`, opt)
      .pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          let httpError = err as HttpErrorResponse;
          this.handleHttpError(httpError);
        }
        return throwError(() => err);
      }));
  }
}

