<div class="row msi-bg msi-margin-s item-view-container">
    <div class="col">
        <div *ngIf="PremiseHazard; then hazardView else emptyView"></div>
        <ng-template #emptyView>
            <div>No Premise Hazard Records Exist</div>
        </ng-template>
        <ng-template #hazardView>
            <div *ngIf="PremiseHazard">
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Name</label>
                        {{PremiseHazard.recordTitle}}
                    </div>
                </div>
                <div *ngIf="isSharedHazard">
                    <div class="row">
                        <div class="col">
                            <msi-callout type="info" closable="false">
                                <div calloutContent>
                                    This premise hazard is shared from another customer or agency and can not be modified
                                </div>
                            </msi-callout>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <msi-callout *ngIf="PremiseHazard.isReviewRequired" type="warning">
                            <div calloutContent>
                                This hazard requires review<br />
                            </div>
                        </msi-callout>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Priority </label>
                        {{PremiseHazard.priority}}
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        Owner Information
                        <div class="msi-margin-s">
                            <div class="row">
                                <div class="col">
                                    <label class="msi-label">Name </label>{{PremiseHazard.ownerName | emptyText}}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <label class="msi-label">Address </label>{{PremiseHazard.ownerAddress | emptyText}}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <label class="msi-label">Phone Number </label>{{PremiseHazard.ownerPhone | emptyText}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label class="msi-label">Comments</label>
                    <div class="msi-padding-s"><p>{{PremiseHazard.comments | emptyText:'No comments added'}}</p></div>
                </div>
                <div *ngIf="PremiseHazard.isTemporary; then isTemporaryHazard">
                </div>
                <div *ngIf="!PremiseHazard.isReviewRequired && PremiseHazard.authorizingPersonnel">
                    <label class="msi-label">Authorized by </label>{{PremiseHazard.authorizingPersonnel | emptyText}}
                </div>
                <div>
                    System Information
                    <div class="msi-padding-s">
                        <div>
                            <label class="msi-label">Created On</label> {{PremiseHazard.insertedTimestamp | date:dateFormat}}
                        </div>
                        <div *ngIf="PremiseHazard.updatedTimestamp">
                            <label class="msi-label">Last Updated</label>{{PremiseHazard.updatedTimestamp | date:dateFormat}}
                        </div>
                        <div>
                            <label class="msi-label">Last Updated By </label>{{PremiseHazard.lastUpdateUserId}}
                        </div>
                        <div>
                            <label class="msi-label">Agency</label>{{PremiseHazard.lastUpdateUserAgencyId | emptyText}}
                        </div>
                    </div>
                </div>
                <div class="msi-padding-s">
                    <div *ngIf="PremiseHazard.contacts && PremiseHazard.contacts.length !== 0; then hasContacts else noContacts"></div>
                </div>
                <div class="msi-padding-s">
                    <div *ngIf="PremiseHazard.attachments && PremiseHazard.attachments.length !== 0; then hasAttachments else noAttachments"></div>
                </div>
                <div>
                    <button class="msi-btn msi-btn-text-and-icon" (click)="addAttachmentClicked()" [disabled]="isSharedHazard">
                        <msi-icon name="ic_upload"/>Upload Attachment
                    </button>
                </div>
                <div class="msi-padding-top-s">
                    <div *ngIf="PremiseHazard.type; then hasType else noType"></div>

                </div>
                <div class="msi-padding-top-s">
                    <div *ngIf="PremiseHazard.address; then hasAddress else noAddress"></div>
                </div>
            </div>
        </ng-template>
    </div>
</div>


<ng-template #attachmentModal let-ref="modalRef">
    <msi-modal #modal [modalRef]="ref">
        <msi-modal-header class="msi-padding-m">
            <label class="msi-label">Upload Attachment</label>
        </msi-modal-header>
        <form [formGroup]="attachmentForm">
            <msi-modal-content class="msi-padding-m">
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Name</label>
                        <input class="msi-input" formControlName="name" style="width: 100%" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">Description</label>
                        <textarea class="msi-textarea" formControlName="description" style="width: 100%"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="msi-label">File</label>
                        <input class="msi-input" type="file" (change)="onFileChanged($event)" style="width: 100%" />
                        <div *ngIf="attachmentForm.controls['filePath'].errors?.['maxSize']">
                            <msi-icon name="ic_error" color="#cc0000" />File size cannot exceed 100MB
                        </div>
                    </div>
                </div>
            </msi-modal-content>
            <msi-modal-actions align="center" class="msi-padding-s">
                <button type="button" class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="ref.close(true)" [disabled]="!attachmentForm.valid">
                    <msi-icon name="ic_upload"/>Upload Attachment
                </button>
                <button type="button" class="msi-btn msi-btn-secondary msi-btn-text-and-icon" (click)="ref.close(false)">
                    <msi-icon name="ic_remove" class="delete-icon"/>Cancel
                </button>
            </msi-modal-actions>
        </form>
    </msi-modal>
</ng-template>

<ng-template #deleteAttachmentModal let-ref="modalRef">
    <msi-modal #modal [modalRef]="ref">
        <msi-modal-header class="msi-padding-m">
            <label class="msi-label">Delete Attachment</label>
        </msi-modal-header>
        <msi-content-content class="msi-padding-s">
            Confirm deletion of attachment<br />
            This action cannot be reversed
        </msi-content-content>
        <msi-modal-actions align="center" class="msi-padding-s">
            <button type="button" class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="ref.close(false)">
                <msi-icon name="ic_remove" class="delete-icon" />Cancel
            </button>
            <button type="button" class="msi-btn msi-btn-primary msi-btn-text-and-icon" (click)="ref.close(true)">
                <msi-icon name="ic_trash" />Delete
            </button>
        </msi-modal-actions>
    </msi-modal>
</ng-template>

<ng-template #loadingModal let-ref="modalRef">
    <msi-modal #modal [modalRef]="ref">
        <msi-modal-header align="center" class="msi-padding-s" style="align-content: center;">
            Processing Request
        </msi-modal-header>
        <msi-modal-content align="center" class="msi-padding-m">
            Do not close this window
            <msi-spinner size="large"></msi-spinner>
        </msi-modal-content>
    </msi-modal>
</ng-template>

<ng-template #hasAddress>
    Address Summary
    <div class="msi-padding-s">
        <div *ngIf="PremiseHazard.address.commonPlace">
            <label class="msi-label">Common Place Name</label>
            {{PremiseHazard.address.commonPlace | emptyText}}
        </div>
        <div>
            <label class="msi-label">Address</label>
            {{PremiseHazard.address.houseAddress | emptyText}}
        </div>
        <div>
            <label class="msi-label">City</label>
            {{PremiseHazard.address.city | emptyText}}
        </div>
        <div>
            <label class="msi-label">State</label>
            {{PremiseHazard.address.state | emptyText}}
        </div>
        <div>
            <label class="msi-label">Zip</label>
            {{PremiseHazard.address.postalCode | emptyText}}
        </div>
        <div>
            <label class="msi-label">
                Coordinates
            </label>
            {{PremiseHazard.address.longitude}}, {{PremiseHazard.address.latitude}}
        </div>
    </div>
</ng-template>

<ng-template #noAddress>
    <msi-callout type="error">
        <div calloutContent>
            This Premise Hazard is not associated with an Address and will not show up in searches.
        </div>
    </msi-callout>
</ng-template>

<ng-template #noContacts>
    <msi-callout type="info">
        <div calloutContent>
            Premise Hazard has no contacts
        </div>
    </msi-callout>
</ng-template>

<ng-template #hasContacts>
    <label class="msi-label">Contacts</label>
    <div class="msi-margin-left-m msi-margin-right-m" *ngFor="let contact of PremiseHazard.contacts">
        <div class="form-row">
            <div class="col">
                <label class="msi-label">Contact Name</label>
            </div>
            <div class="col">
                {{contact.name | emptyText}}
            </div>
        </div>
        <div class="col">
            <label class="msi-label">Created </label>
            {{contact.insertedTimestamp | date:dateFormat}}
        </div>
        <div class="col">
            <label class="msi-label" *ngIf="contact.updatedTimestamp">Updated</label>{{contact.updatedTimestamp | date:dateFormat}}
        </div>
        <div class="col msi-padding-left-m msi-padding-right-m">
            <div *ngIf="contact.devices && contact.devices.length !== 0; then devices else noDevices"></div>
            <ng-template #devices>
                <label class="msi-label">Devices</label>
                <div *ngFor="let device of contact.devices">
                    <label class="msi-label">Device Type: {{device.deviceType}}</label>
                    <label class="msi-label">Address: {{device.address | emptyText}}</label>
                    <label class="msi-label">Created: {{device.insertedTimestamp | date:dateFormat}}</label>
                    <label class="msi-label" *ngIf="device.updatedTimestamp">Updated: {{device.updatedTimestamp | date:dateFormat}}</label>
                </div>
            </ng-template>
            <ng-template #noDevices>
                <msi-callout type="info">
                    <div calloutContent>
                        This contact has no devices
                    </div>
                </msi-callout>
            </ng-template>
        </div>
    </div>
</ng-template>

<ng-template #hasType>
    Hazard Type Summary
    <div class="msi-padding-s"><label class="msi-label">Name: </label>{{PremiseHazard.type.code | emptyText}}</div>
    <div class="msi-padding-s"><label class="msi-label">Description: </label>{{PremiseHazard.type.description | emptyText}}</div>
    <div class="msi-padding-s"><label class="msi-label">Created: </label>{{PremiseHazard.type.insertedTimestamp | date:dateFormat}}</div>
</ng-template>

<ng-template #noType>
    <msi-callout type="warning" *ngIf="!PremiseHazard.type">
        <div calloutContent>
            Premise Hazard is not associated with a Type, which could limit visibility in searches.
        </div>
    </msi-callout>
</ng-template>

<ng-template #hasAttachments>
    <label class="msi-label">Attachments</label>
    <div class="msi-padding-s" *ngFor="let attachment of PremiseHazard.attachments">
        <label class="msi-label" *ngIf="attachment.attachmentName">{{attachment.attachmentName | emptyText}}</label>
        <msi-icon name="ic_warning" msiTooltip="The attachment is still being processed, it can be viewed or deleted after processing is complete" color="#eed202" />Attachment is still being processed
        <label class="msi-label">File Name:</label> {{attachment.fileName | emptyText}}
        <label class="msi-label">Created:</label> {{attachment.createdTimestamp | date}}
        <label class="msi-label" *ngIf="attachment.updatedTimestamp">Updated: {{attachment.updatedTimestamp | date:dateFormat}}</label>
        <label class="msi-label">Size:</label>{{attachment.fileSize}}
        <label class="msi-label">Type:</label>{{attachment.fileType}}
        <div *ngIf="attachment.isComplete">
            <label class="msi-label">
                Upload Completed: {{attachment.whenUploadToCcDriveCompleted | date:dateFormat}}
            </label>
            <div class="row">
                <div class="col msi-padding-s">
                    <button class="msi-btn msi-btn-text-and-icon" (click)="onViewAttachmentClicked(attachment)">
                        <msi-icon name="ic_download"/>View/Download Attachment
                    </button>
                </div>
                <div class="col msi-padding-s">
                    <button class="msi-btn msi-btn-text-and-icon" (click)="deleteAttachmentClicked(attachment)" [disabled]="isSharedHazard">
                        <msi-icon name="ic_remove" class="delete-icon"/> Delete Attachment
                    </button>
                </div>
            </div>
        </div>
        <label class="msi-label">
            Comments:
        </label>
        <p>{{attachment.comments | emptyText:"No comments added"}}</p>
    </div>
</ng-template>

<ng-template #noAttachments>
    <msi-callout type="info">
        <div calloutContent>
            Hazard has no attachments
        </div>
    </msi-callout>
</ng-template>

<ng-template #isTemporaryHazard>
    Temporary Hazard Information
    <div class="msi-padding-m">
        <div class="row">
            <div class="col">
                <label class="msi-label">Purge Date </label>{{PremiseHazard.purgeDate | date:dateFormat}}
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="msi-label">Purge Type </label>{{PremiseHazard.purgeType | emptyText:"No purge type provided"}}
            </div>
        </div>
    </div>
</ng-template>
