import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { PremiseHazardType } from '../../premise-hazards/premise-hazard-type.model';
import { Units } from '../../premise-hazards/units.model';
import { UserService } from '../../services/user.service';
import { UnitConverterService } from '../../unit-converter.service';

@Component({
  selector: 'app-premise-hazard-type-view',
  templateUrl: './premise-hazard-type-view.component.html',
  styleUrls: ['./premise-hazard-type-view.component.scss'],
})
export class PremiseHazardTypeViewComponent implements OnInit, OnDestroy, OnChanges {
  DEFAULT_UNITS = Units.Foot;
  @Input() PremiseHazardType: PremiseHazardType;
  @Output() isSharedTypeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  dateFormat: string = 'dd/MM/yyyy HH:mm a zzzz';

  defaultDirectHitDistanceMeters: number = 5;

  selectedUnits: Units = this.DEFAULT_UNITS;
  UNITS = Object.values(Units).map(val => val.toString());
  NUMBER_FORMATS = ['1.0-3', '1.0-2', '1.0-5', '1.0-6'];
  UNITS_TEXT = ['feet', 'meters', 'kilometers', 'miles'];

  public isSharedHazardType: boolean = false;

  directHitDistanceDisplay: number = 0;
  innerProximityDistanceDisplay: number = 0;
  outerProximityDistanceDisplay: number = 0;
  showExpandedInformation: boolean = true;

  constructor(private converterService: UnitConverterService,
    private userService: UserService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.PremiseHazardType) {
      this.showExpandedInformation = true;
      //= !(this.PremiseHazardType.directHitAudio === null &&
      //  this.PremiseHazardType.directHitVisual === null &&
      //  this.PremiseHazardType.innerProximityAudio === null &&
      //  this.PremiseHazardType.innerProximityVisual === null &&
      //  this.PremiseHazardType.outerProximityAudio === null &&
      //  this.PremiseHazardType.outerProximityVisual === null);
      this.updateProximities();
      this.setSharingInfo();
    }
  }

  ngOnInit(): void {
    if (this.PremiseHazardType) {
      this.showExpandedInformation = !(this.PremiseHazardType.directHitAudio === null &&
        this.PremiseHazardType.directHitVisual === null &&
        this.PremiseHazardType.innerProximityAudio === null &&
        this.PremiseHazardType.innerProximityVisual === null &&
        this.PremiseHazardType.outerProximityAudio === null &&
        this.PremiseHazardType.outerProximityVisual === null);
      this.updateProximities();
    }
  }
  ngOnDestroy(): void { }

  setPremiseHazardType(premiseHazardType: PremiseHazardType): void {
    this.PremiseHazardType = premiseHazardType;
  }

  onSelectedUnitsChanged(value: string): void {
    let units = Units[value];
    if (units !== undefined) {
      this.selectedUnits = units;
      this.updateProximities();
    }
  }

  private setSharingInfo(): void {
    if (!this.PremiseHazardType) {
      return;
    }
    this.userService.UserInformation.pipe(take(1)).subscribe(userInfo => {
      if (!userInfo || !userInfo.customerId) {
        return;
      }
      const customerEquals = this.PremiseHazardType.ccCustomer.localeCompare(userInfo.customerId, undefined, { caseFirst: 'lower' }) === 0;
      const agencyEquals = this.PremiseHazardType.ccAgency.localeCompare(userInfo.agencyId, undefined, { caseFirst: 'lower' }) === 0;
      this.isSharedHazardType = !(customerEquals && agencyEquals);
      this.isSharedTypeEvent.emit(this.isSharedHazardType);
      this.cdr.detectChanges();
    });
  }

  private updateProximities(): void {
    if (this.PremiseHazardType.directHitDistance) {
      this.directHitDistanceDisplay = this.converterService.fromMeters(this.PremiseHazardType.directHitDistance, this.selectedUnits);
    } else {
      this.directHitDistanceDisplay = this.converterService.fromMeters(this.defaultDirectHitDistanceMeters, this.selectedUnits);
    }
    this.innerProximityDistanceDisplay = this.converterService.fromMeters(this.PremiseHazardType.innerProximityDistance, this.selectedUnits);
    this.outerProximityDistanceDisplay = this.converterService.fromMeters(this.PremiseHazardType.outerProximityDistance, this.selectedUnits);
  }
}
